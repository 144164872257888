.Toastify__toast {
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
.Toastify__toast--info {
  background-color: #3197c4 !important;
}
.Toastify__toast--success {
  background: #4dc86a !important;
}
.Toastify__toast--warning {
  background: #ec9933 !important;
}
.Toastify__toast--error {
  background: #db2828 !important;
}
