@import (reference) '~semantic-ui-less/definitions/globals/site';

body {
  margin: 0;
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  width: 100%;
  height: 100%;
}

body.audit {
  background-color: lighten(@purple, 55%);
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button.ui.button.basic.tertiary, button.ui.button.basic.tertiary:hover, button.ui.button.basic.tertiary:focus {
  box-shadow: 0 0 !important;
  padding: 0;
}

svg text[font-family="FontAwesome5FreeSolid, Font Awesome 5 Free"] {
  font-family: Icons;
}

.meta {
  color: @metaTextColor;
}