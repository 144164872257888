.App {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr;
  grid-template-areas: "header header header" "menu content audit";
  translate: 500ms ease;
}
header {
  grid-area: header;
}
nav {
  grid-area: menu;
}
.App-content {
  grid-area: content;
  overflow-y: auto;
  overflow-x: hidden;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
}
.App-content main {
  padding: 21px;
}
