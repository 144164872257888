@import (reference) '~semantic-ui-less/definitions/globals/site';

header {
    height: 63px;

    .proc_app-header__logo {
        padding: 21px;
    }
}

button.proc_auditor-button {
    font-size: 100%;
    font-family: inherit;
    border: 0;
    padding: 0;
    background-color: white;

    > div {
        background-color: @grey;
        padding-left: 3.5px;
        cursor: pointer;

        &:hover {
            background-color: @purple;
        }
    }

    &:focus {
        outline: none;

        > div {
            border: double 4px white;
        }
    }

}
