@import (reference) '~semantic-ui-less/definitions/globals/site';

.audit-sidebar {
    grid-area: audit;
    transition: 500ms ease;
    width: 360px;
    background-color: white;

    div.ui.right.sidebar {
        display: flex;
        flex-direction: column;
    }

    &:not(.visible) {
        width: 0;

        div.ui.right.sidebar {
            width: 0;
        }
    }

    .connect-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: @purple;
        color: white;
        height: 42px;
        line-height: 42px;
        padding: 0 14px;
        margin: 7px 14px 21px;

        span {
            font-weight: 300;
            flex-grow: 1;
            text-align: right;
            margin-right: 7px;
        }
    }

    .connect-pending {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: lighten(@purple, 55%);
        height: 42px;
        line-height: 42px;
        padding: 0 14px;
        margin: 7px 14px 21px;
        border: 1px solid @purple;

        span {
            font-weight: 300;
            flex-grow: 1;
            text-align: right;
            margin-right: 7px;
        }
    }

    .auditor-select {
        margin: 0 14px 21px;
    }

    .audit-log {
        background-color: lighten(@purple, 55%);
        margin-top: 21px;
        padding: 21px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 1vh;
        overflow-y: auto;

        > div {
            display: flex;
            margin-bottom: 14px;

            span:first-child {
                margin-right: 14px;
            }
        }
    }

    .audit-request {
        background-color: lighten(@purple, 55%);
        padding: 7px;

        > div:first-child {
            text-align: center;
            color: @purple;
        }

        > .ui.segment {
            margin: 7px 21px 21px;
            text-align: center;

            .proc_circle-icon {
                margin: 0 auto 14px;
                background-color: @green;
            }
            .meta {
                color: @metaTextColor;
                font-size: 12px;
            }

            button.icon {
                position: absolute;
                right: 0;
            }

            button:not(.icon) {
                display: block;
                margin: 21px auto 7px;
            }
        }
    }

    .audit-history {
        h1 {
            color: @purple;
            text-align: center;
            font-size: 16px;
            margin-top: 14px;
        }
        td:first-child {
            color: @metaTextColor;
        }
    }
}

div.ui.right.sidebar {
    position: relative;
    width: 360px;
}