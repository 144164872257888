.audit-sidebar {
  grid-area: audit;
  transition: 500ms ease;
  width: 360px;
  background-color: white;
}
.audit-sidebar div.ui.right.sidebar {
  display: flex;
  flex-direction: column;
}
.audit-sidebar:not(.visible) {
  width: 0;
}
.audit-sidebar:not(.visible) div.ui.right.sidebar {
  width: 0;
}
.audit-sidebar .connect-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #94448f;
  color: white;
  height: 42px;
  line-height: 42px;
  padding: 0 14px;
  margin: 7px 14px 21px;
}
.audit-sidebar .connect-info span {
  font-weight: 300;
  flex-grow: 1;
  text-align: right;
  margin-right: 7px;
}
.audit-sidebar .connect-pending {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fbf6fa;
  height: 42px;
  line-height: 42px;
  padding: 0 14px;
  margin: 7px 14px 21px;
  border: 1px solid #94448f;
}
.audit-sidebar .connect-pending span {
  font-weight: 300;
  flex-grow: 1;
  text-align: right;
  margin-right: 7px;
}
.audit-sidebar .auditor-select {
  margin: 0 14px 21px;
}
.audit-sidebar .audit-log {
  background-color: #fbf6fa;
  margin-top: 21px;
  padding: 21px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 1vh;
  overflow-y: auto;
}
.audit-sidebar .audit-log > div {
  display: flex;
  margin-bottom: 14px;
}
.audit-sidebar .audit-log > div span:first-child {
  margin-right: 14px;
}
.audit-sidebar .audit-request {
  background-color: #fbf6fa;
  padding: 7px;
}
.audit-sidebar .audit-request > div:first-child {
  text-align: center;
  color: #94448f;
}
.audit-sidebar .audit-request > .ui.segment {
  margin: 7px 21px 21px;
  text-align: center;
}
.audit-sidebar .audit-request > .ui.segment .proc_circle-icon {
  margin: 0 auto 14px;
  background-color: #4dc86a;
}
.audit-sidebar .audit-request > .ui.segment .meta {
  color: #848484;
  font-size: 12px;
}
.audit-sidebar .audit-request > .ui.segment button.icon {
  position: absolute;
  right: 0;
}
.audit-sidebar .audit-request > .ui.segment button:not(.icon) {
  display: block;
  margin: 21px auto 7px;
}
.audit-sidebar .audit-history h1 {
  color: #94448f;
  text-align: center;
  font-size: 16px;
  margin-top: 14px;
}
.audit-sidebar .audit-history td:first-child {
  color: #848484;
}
div.ui.right.sidebar {
  position: relative;
  width: 360px;
}
