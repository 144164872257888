header {
  height: 63px;
}
header .proc_app-header__logo {
  padding: 21px;
}
button.proc_auditor-button {
  font-size: 100%;
  font-family: inherit;
  border: 0;
  padding: 0;
  background-color: white;
}
button.proc_auditor-button > div {
  background-color: #767676;
  padding-left: 3.5px;
  cursor: pointer;
}
button.proc_auditor-button > div:hover {
  background-color: #94448f;
}
button.proc_auditor-button:focus {
  outline: none;
}
button.proc_auditor-button:focus > div {
  border: double 4px white;
}
