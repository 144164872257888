@import (reference) '~semantic-ui-less/definitions/globals/site';

.Toastify__toast {
    font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.Toastify__toast--info {
    background-color: @blue !important; }
.Toastify__toast--success {
    background: @green !important; }
  .Toastify__toast--warning {
    background: @orange !important; }
  .Toastify__toast--error {
    background: @red !important; }